import React from "react";
import {Button} from "reactstrap";
import trainestIcon from "../icons/home/ic-logo-icon.svg";
import Image from "next/legacy/image";
import {useRouter} from 'next/router'

export default function Custom404() {
  const router = useRouter();

  const handleContinue = () => router.push('/');

  return (
    <div className="error-404">
      <div className="code">
        4
        <Image
          src={trainestIcon}
          alt="Trainest"
          width={218}
          height={207}
        />
        4
      </div>
      <h2>Opps! Page Not Found</h2>
      <Button color="primary" onClick={handleContinue}>Continue</Button>
    </div>
  );
}